import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { match } from 'react-router-dom';
import { Button } from '../../../../global/components/button';
import { TextInput } from '../../../../global/components/form-controls/text-input/text-input';
import { InfoItem } from '../../../../global/components/info-item';
import { InfoLine } from '../../../../global/components/info-line';
import { parseDateForApi } from '../../../../global/helpers/date.helper';
import { goToState } from '../../../../global/helpers/url-parse.helper';
import { useSpinner } from '../../../../global/hooks/use-spinner';
import { MemberSex, MemberSexItem } from '../../models/member-sex.model';
import {
    MemberStatus,
    MemberStatusItem,
} from '../../models/member-status.model';
import {
    clearAdoptedInfoCard,
    clearMemberCard,
    loadAdoptedInfoCard,
    loadMemberCard,
    updateMemberCard,
} from '../../redux/members.actions';
import { getAdoptedInfoCard, getMemberCard } from '../../redux/members.selectors';
import { CreateMemberParams } from '../../types/create-member-params';
import { Footer, Header, Root } from './member-edit.styles';
import { AuthService } from '../../../../auth/services/AuthService';
import { TextInputMask } from '../../../../global/components/form-controls/text-input-mask/text-input-mask';
import { BottomPadding } from '../../../../global/components/main-wrap/main-wrap.styles';
import { Checkbox, FormControlLabel } from '@mui/material';
import { Calendar } from 'primereact/calendar';
import { useEventValue } from '../../../../global/hooks/use-event-value';
import MultipleSelectEntity, { EntityForSelect } from '../../../../global/components/multiple-select-entity/multiple-select-entity';
import { AbsenteeLetterStatusItem, absenteeLetterStatusOptions } from '../../models/absentee-letter-status.model';
import { TestimonyStatusItem, testimonyStatusOptions } from '../../models/testimony-status.model';
import { TestimonyRevisionItem, testimonyRevisionOptions } from '../../models/testimony-revision.model';
import { InterviewResultItem, interviewResultOptions } from '../../models/interview-result.model';
import { loadMembersForSelect, parseMemberItemToEntityForSelect, parseMembersListToEntityForSelect } from '../../helpers/api.helpers';
import { CreateAdoptedInfoParams } from '../../types/create-adopted-info-params';
import { CardHeader } from '../card/member-card.styles';
import SingleSelectEntity from '../../../../global/components/single-select-entity/single-select-entity';

type MemberEditPageRouteParams = {
    id: string;
};

type MemberEditProp = {
    match: match<MemberEditPageRouteParams>;
};

const MemberEdit = (props: MemberEditProp) => {
    const showForAdmin = useMemo(
        () => AuthService.availableForAdmin(),
        [AuthService.availableForAdmin],
    );
    const dispatch = useDispatch();
    const memberCard = useSelector(getMemberCard);
    const adoptedInfoCard = useSelector(getAdoptedInfoCard);
    const [id, setId] = useState<number | null>(null);
    const spinnerRunner = useSpinner(dispatch);
    const [firstName, setFirstName] = useState<string | null>(null);
    const [lastName, setLastName] = useState<string | null>(null);
    const [patronymic, setPatronymic] = useState<string | null>(null);
    const [phone, setPhone] = useState<string | null>(null);
    const [sex, setSex] = useState<MemberSexItem | null>(null);
    const [status, setStatus] = useState<MemberStatusItem | null>(null);
    const [email, setEmail] = useState<string | null>(null);
    const [birthDay, setBirthDay] = useState<Date | null>(null);
    const [birthPlace, setBirthPlace] = useState<string | null>(null);
    const [skills, setSkills] = useState<string | null>(null);
    const [repentanceYear, setRepentanceYear] = useState<string | null>(null);
    const [repentancePlace, setRepentancePlace] = useState<string | null>(null);
    const [baptismYear, setBaptismYear] = useState<string | null>(null);
    const [witnessesDate, setWitnessesDate] = useState<Date | null>(null);
    const [adoptedDate, setAdoptedDate] = useState<Date | null>(null);
    const [disposalDate, setDisposalDate] = useState<Date | null>(null);
    const [notCongratulation, setNotCongratulation] = useState<boolean>(false);
    const [rbcBaptism, setRbcBaptism] = useState<boolean>(false);
    const [completeAu, setCompleteAU] = useState<boolean>(false);

    const [absenteeLetterStatus, setAbsenteeLetterStatus] = useState<AbsenteeLetterStatusItem | null>(null);
    const [testimonyStatus, setTestimonyStatus] = useState<TestimonyStatusItem | null>(null);
    const [testimonyRevision, setTestimonyRevision] = useState<TestimonyRevisionItem | null>(null);
    const [testimonyLink, setTestimonyLink] = useState<string | null>(null);
    const [interviewDate, setInterviewDate] = useState<Date | null>(null);
    const [interviewTime, setInterviewTime] = useState<string | null>(null);
    const [interviewResult, setInterviewResult] = useState<InterviewResultItem | null>(null);
    const [ministryInfo, setMinistryInfo] = useState<string | null>(null);
    const [personalLeader, setPersonalLeader] = useState<EntityForSelect | null>(null);
    const [presentForChurch, setPresentForChurch] = useState<EntityForSelect | null>(null);
    const [interviewPastors, setInterviewPastors] = useState<EntityForSelect[]>([]);

    const handleBirthDateChange = useEventValue(setBirthDay);
    const handleWitnessesDateChange = useEventValue(setWitnessesDate);
    const handleAdoptedDateChange = useEventValue(setAdoptedDate);
    const handleDisposalDateChange = useEventValue(setDisposalDate);
    const handleInterviewDateChange = useEventValue(setInterviewDate);

    useEffect(() => {
        if (!showForAdmin) {
            goToState('/');
            return;
        }
    }, []);

    useEffect(() => {
        if (props.match.params.id && Number(props.match.params.id) !== id) {
            dispatch(clearMemberCard());
            dispatch(clearAdoptedInfoCard());
            dispatch(loadMemberCard(Number(props.match.params.id)));
            if (showForAdmin) {
                dispatch(loadAdoptedInfoCard(Number(props.match.params.id)));
            }
        }
    }, [props.match.params.id]);

    useEffect(() => {
        if (memberCard) {
            setId(memberCard.id);
            setFirstName(memberCard.firstName);
            setLastName(memberCard.lastName);
            setPatronymic(memberCard.patronymic);
            setPhone(memberCard.phone);
            setSex(memberCard.sex);
            setStatus(memberCard.status);
            setEmail(memberCard.email);
            setBirthDay(memberCard.birthDay);
            setBirthPlace(memberCard.birthPlace);
            setSkills(memberCard.skills);
            setRbcBaptism(!!memberCard.rbcBaptism);
            setRepentanceYear(`${memberCard.repentanceYear ?? ''}`);
            setRepentancePlace(memberCard.repentancePlace);
            setBaptismYear(`${memberCard.baptismYear ?? ''}`);
            setWitnessesDate(memberCard.witnessesDate);
            setAdoptedDate(memberCard.adoptedDate);
            setDisposalDate(memberCard.disposalDate);
            setNotCongratulation(!!memberCard.notCongratulation);
            setCompleteAU(!!memberCard.completeAu);
        }
    }, [memberCard]);

    useEffect(() => {
        if (adoptedInfoCard) {
            setAbsenteeLetterStatus(adoptedInfoCard.absenteeLetterStatus);
            setTestimonyStatus(adoptedInfoCard.testimonyStatus);
            setTestimonyRevision(adoptedInfoCard.testimonyRevision);
            setTestimonyLink(adoptedInfoCard.testimonyLink);
            setInterviewDate(adoptedInfoCard.interviewDate);
            setInterviewTime(adoptedInfoCard.interviewTime);
            setInterviewResult(adoptedInfoCard.interviewResult);
            setMinistryInfo(adoptedInfoCard.ministryInfo);
            setPersonalLeader(parseMemberItemToEntityForSelect(adoptedInfoCard.personalLeader));
            setPresentForChurch(parseMemberItemToEntityForSelect(adoptedInfoCard.presentForChurch));
            setInterviewPastors(parseMembersListToEntityForSelect(adoptedInfoCard.interviewPastors));
        }
    }, [adoptedInfoCard]);

    const statusOptions = useMemo(() => Object.values(MemberStatus), []);
    const sexOptions = useMemo(() => Object.values(MemberSex), []);

    const isValidForm = useMemo(
        () =>
            !!firstName &&
            !!lastName &&
            !!phone &&
            phone.indexOf('_') === -1 &&
            !!status &&
            !!sex &&
            !!email,
        [firstName, lastName, phone, status, sex, email],
    );

    const saveCallback = useCallback(() => {
        if (
            !id ||
            !firstName ||
            !lastName ||
            !phone ||
            phone.indexOf('_') !== -1 ||
            !status ||
            !sex ||
            !email
        ) {
            return;
        }
        const params: CreateMemberParams = {
            firstName,
            lastName,
            patronymic,
            phone,
            status: status.value,
            email,
            sex: sex.value,
            bDay: parseDateForApi(birthDay),
            birthPlace,
            skills,
            repentanceYear,
            repentancePlace,
            baptismYear,
            witnessesDate: parseDateForApi(witnessesDate),
            adoptedDate: parseDateForApi(adoptedDate),
            disposalDate: parseDateForApi(disposalDate),
            externalId: null,
            notCongratulation: !!notCongratulation,
            completeAu: !!completeAu,
            rbcBaptism,
        };
        const adoptedInfoParams: CreateAdoptedInfoParams = {
            absenteeLetterStatus: absenteeLetterStatus?.value || null,
            interviewDate: parseDateForApi(interviewDate),
            interviewPastorsIds: interviewPastors.map(el => el.id),
            interviewResult: interviewResult?.value || null,
            interviewTime,
            ministryInfo,
            personalLeaderId: personalLeader?.id || null,
            presentForChurchId: presentForChurch?.id || null,
            testimonyLink,
            testimonyRevision: testimonyRevision?.value || null,
            testimonyStatus: testimonyStatus?.value || null,
        };
        spinnerRunner(updateMemberCard(id, params, adoptedInfoParams));
    }, [
        testimonyStatus,
        testimonyRevision,
        testimonyLink,
        personalLeader,
        presentForChurch,
        ministryInfo,
        interviewTime,
        interviewResult,
        interviewPastors,
        interviewDate,
        absenteeLetterStatus,
        firstName,
        lastName,
        patronymic,
        phone,
        status,
        email,
        sex,
        birthDay,
        birthPlace,
        skills,
        repentanceYear,
        repentancePlace,
        baptismYear,
        witnessesDate,
        adoptedDate,
        disposalDate,
        spinnerRunner,
        updateMemberCard,
        notCongratulation,
        completeAu,
        rbcBaptism,
    ]);

    const fio = useMemo(() => {
        let result: string = '';
        if (!memberCard) {
            return result;
        }
        if (memberCard.lastName) {
            result = `${memberCard.lastName}`;
        }
        if (memberCard.firstName) {
            result = `${result}${result ? ' ' : ''}${memberCard.firstName}`;
        }
        if (memberCard.patronymic) {
            result = `${result}${result ? ' ' : ''}${memberCard.patronymic}`;
        }
        return result;
    }, [memberCard]);

    const goToList = useCallback(() => {
        goToState('/members');
    }, []);

    const goToCard = useCallback(() => {
        goToState(`/member/${props.match.params.id}`);
    }, []);

    const toggleNotCongratulation = useCallback(() => {
        setNotCongratulation(!notCongratulation);
    }, [notCongratulation]);

    const toggleRbcBatism = useCallback(() => {
        setRbcBaptism(!rbcBaptism);
    }, [rbcBaptism]);

    const toggleCompleteAU = useCallback(() => {
        setCompleteAU(!completeAu);
    }, [completeAu]);

    if (!memberCard) {
        return (
            <Root>
                <Header>
                    <Button title="Перейти в список" onClick={goToList} />
                </Header>
            </Root>
        );
    }

    return (
        <Root>
            <Header>
                <Button title="Перейти в список" onClick={goToList} />
                <Button title="Перейти в карточку" onClick={goToCard} />
            </Header>
            <InfoLine>
                <InfoItem title="id" value={id} />
                <InfoItem title="ФИО" value={fio} />
            </InfoLine>
            <InfoLine>
                <InfoItem
                    title="Фамилия *"
                    value={
                        <TextInput value={lastName} onChange={setLastName} />
                    }
                />
                <InfoItem
                    title="Имя *"
                    value={
                        <TextInput value={firstName} onChange={setFirstName} />
                    }
                />
                <InfoItem
                    title="Отчество"
                    value={
                        <TextInput
                            value={patronymic}
                            onChange={setPatronymic}
                        />
                    }
                />
                {/* <InfoItem
                    title="Внешний id (из гугл.таблицы)"
                    value={<TextInput
                        value={externalId}
                        onChange={setExternalId}
                    />}
                /> */}
            </InfoLine>
            <InfoLine>
                <InfoItem
                    title="Телефон *"
                    value={
                        <TextInputMask
                            value={phone}
                            mask="+99999999999"
                            onChange={setPhone}
                        />
                    }
                />
                <InfoItem
                    title="E-mail *"
                    value={<TextInput value={email} onChange={setEmail} />}
                />
                <InfoItem
                    title="Церковный статус *"
                    value={
                        <Select
                            options={statusOptions}
                            defaultValue={status}
                            onChange={setStatus}
                            isClearable
                        />
                    }
                />
            </InfoLine>
            <InfoLine>
                <FormControlLabel
                    className="filter-item filter-item-checkbox"
                    control={
                        <Checkbox
                            checked={rbcBaptism}
                            onClick={toggleRbcBatism}
                        />
                    }
                    label="Крещён в РБЦ"
                />
            </InfoLine>
            <InfoLine>
                <FormControlLabel
                    className="filter-item filter-item-checkbox"
                    control={
                        <Checkbox
                            checked={notCongratulation}
                            onClick={toggleNotCongratulation}
                        />
                    }
                    label="Не поздравлять с днём рождения"
                />
            </InfoLine>
            <InfoLine>
                <InfoItem
                    title="Пол *"
                    value={
                        <Select
                            options={sexOptions}
                            defaultValue={sex}
                            onChange={setSex}
                            isClearable
                        />
                    }
                />
                <InfoItem
                    title="Дата рождения"
                    value={
                        <Calendar
                            locale={'ru'}
                            className="local-datapicker"
                            value={birthDay}
                            onChange={handleBirthDateChange}
                            dateFormat={'dd.mm.yy'}
                        />
                    }
                />
                <InfoItem
                    title="Место рождения"
                    value={
                        <TextInput
                            value={birthPlace}
                            onChange={setBirthPlace}
                        />
                    }
                />
            </InfoLine>
            <InfoItem
                title="Способности"
                value={
                    <TextInput value={skills} onChange={setSkills} asTextarea />
                }
            />
            <InfoLine>
                <InfoItem
                    title="Год покаяния"
                    value={
                        <TextInput
                            value={repentanceYear}
                            onChange={setRepentanceYear}
                        />
                    }
                />
                <InfoItem
                    title="Место покаяния"
                    value={
                        <TextInput
                            value={repentancePlace}
                            onChange={setRepentancePlace}
                        />
                    }
                />
                <InfoItem
                    title="Год крещения"
                    value={
                        <TextInput
                            value={baptismYear}
                            onChange={setBaptismYear}
                        />
                    }
                />
            </InfoLine>
            <InfoLine>
                <InfoItem
                    title="Дата рассказывания свидетельства"
                    value={
                        <Calendar
                            locale={'ru'}
                            className="local-datapicker"
                            value={witnessesDate}
                            onChange={handleWitnessesDateChange}
                            dateFormat={'dd.mm.yy'}
                        />
                    }
                />
                <InfoItem
                    title="Дата принятия в члены церкви"
                    value={
                        <Calendar
                            locale={'ru'}
                            className="local-datapicker"
                            value={adoptedDate}
                            onChange={handleAdoptedDateChange}
                            dateFormat={'dd.mm.yy'}
                        />
                    }
                />
                <InfoItem
                    title="Дата отбытия"
                    value={
                        <Calendar
                            locale={'ru'}
                            className="local-datapicker"
                            value={disposalDate}
                            onChange={handleDisposalDateChange}
                            dateFormat={'dd.mm.yy'}
                        />
                    }
                />
            </InfoLine>
            <InfoLine>
                <FormControlLabel
                    className="filter-item filter-item-checkbox"
                    control={
                        <Checkbox
                            checked={completeAu}
                            onClick={toggleCompleteAU}
                        />
                    }
                    label="Прошёл АУ"
                />
            </InfoLine>
            {
                !!showForAdmin && memberCard.status.value === MemberStatus.OV.value && !!adoptedInfoCard &&
                <div>
                    <CardHeader>Подготовка к членству</CardHeader>
                    <InfoLine>
                        <InfoItem
                            valueWidth={157}
                            title="Cтатус открепительного письма "
                            value={
                                <Select
                                    options={absenteeLetterStatusOptions}
                                    defaultValue={absenteeLetterStatus}
                                    onChange={setAbsenteeLetterStatus}
                                    isClearable
                                />
                            }
                        />
                        <InfoItem
                            valueWidth={157}
                            title="Статус свидетельства "
                            value={
                                <Select
                                    options={testimonyStatusOptions}
                                    defaultValue={testimonyStatus}
                                    onChange={setTestimonyStatus}
                                    isClearable
                                />
                            }
                        />
                        <InfoItem
                            valueWidth={157}
                            title="Cтатус доработки свидетельства "
                            value={
                                <Select
                                    options={testimonyRevisionOptions}
                                    defaultValue={testimonyRevision}
                                    onChange={setTestimonyRevision}
                                    isClearable
                                />
                            }
                        />
                        <InfoItem
                            title="Cсылка на свидетельство "
                            value={
                                <TextInput
                                    value={testimonyLink}
                                    onChange={setTestimonyLink}
                                />
                            }
                        />
                    </InfoLine>
                    <InfoLine>
                        <InfoItem
                            title="Дата собеседования "
                            value={
                                <Calendar
                                    locale={'ru'}
                                    className="local-datapicker"
                                    value={interviewDate}
                                    onChange={handleInterviewDateChange}
                                    dateFormat={'dd.mm.yy'}
                                />
                            }
                        />
                        <InfoItem
                            title="Время собеседования "
                            value={
                                <TextInput
                                    value={interviewTime}
                                    onChange={setInterviewTime}
                                />
                            }
                        />
                        <InfoItem
                            valueWidth={157}
                            title="Результат собеседования "
                            value={
                                <Select
                                    options={interviewResultOptions}
                                    defaultValue={interviewResult}
                                    onChange={setInterviewResult}
                                    isClearable
                                />
                            }
                        />
                    </InfoLine>
                    <InfoLine>
                        <InfoItem
                            title="В каком служении находится "
                            value={
                                <TextInput
                                    value={ministryInfo}
                                    onChange={setMinistryInfo}
                                />
                            }
                        />
                        <SingleSelectEntity
                            label="Ответственный лидер"
                            maxWidth={157}
                            value={personalLeader}
                            onChange={setPersonalLeader}
                            loadOptions={loadMembersForSelect}
                        />
                        <SingleSelectEntity
                            label="Кто представляет церкви"
                            maxWidth={157}
                            value={presentForChurch}
                            onChange={setPresentForChurch}
                            loadOptions={loadMembersForSelect}
                        />
                        <MultipleSelectEntity
                            label="Собеседующие пастора"
                            maxWidth={157}
                            values={interviewPastors}
                            onChange={setInterviewPastors}
                            loadOptions={loadMembersForSelect}
                        />
                    </InfoLine>
                </div>
            }
            <Footer>
                <Button
                    title="Сохранить"
                    onClick={saveCallback}
                    disabled={!isValidForm}
                />
            </Footer>
            <BottomPadding />
        </Root>
    );
};

export default MemberEdit;

import styled from 'styled-components/macro';

const Root = styled.div`
    width: 100%;
`;

const MapWrap = styled.div`
    width: 700px;
    height: 300px;
`;

export { Root, MapWrap };


type DefaultErrorMessageType = {
    [index: string]: string;
};

export const backspaceKey = 'Backspace';
export const ctrlKey = 'Control';
export const commandKey = 'Meta';
export const plusKey = '=';
export const minusKey = '-';
export const shiftKey = 'Shift';
export const defaultToastTimeout: number = 5000;
export const defaultErrorMessage: DefaultErrorMessageType = {
    'Error: Request failed with status code 404': 'Ошибка! Страница не найдена',
    'Invalid resource owner credentials': 'Неверный логин или пароль',
    'Unauthorized': 'Вы не авторизованы'
};

export enum LocalSpinnerKeysEnum {
    membersList = 'membersList',
    familyList = 'familyList',
    ministriesList = 'ministriesList',
    resourcesList = 'resourcesList',
    groupsList = 'groupsList',
    chatInfoList = 'chatInfoList',
    messagesList = 'messagesList',
    usersList = 'usersList',
    ovPeopleList = 'ovPeopleList',
    ovCohortList = 'ovCohortList',
};
import { api } from '../../../config/api';
import { BaseRepository, ResolverMethods } from '../../../global/repository/base.repository';
import { apiHost } from '../../../params';

export class SendListRepository extends BaseRepository {
    static defaultUrl = `${apiHost}/api/v1/send-list/`;
    static api = api;

    static uploadSendListFile(file: File) {
        const formData = new FormData();
        formData.append('data', file);

        return SendListRepository.resolveWithoutModel<void>({
            url: 'create-send-list-from-csv',
            bodyData: formData,
            method: ResolverMethods.post,
        });
    };
}

import { GroupsActions, GroupsActionsEnum, GroupsState } from './groups.types';

const initialState = new GroupsState();

const groupsReducer = (state = initialState, action: GroupsActions) => {
    switch (action.type) {
        case GroupsActionsEnum.updateGroups:
            return {
                ...state,
                groups: action.payload.groups,
                totalCount: action.payload.totalCount,
            };
        case GroupsActionsEnum.updateGroupCard:
            return {
                ...state,
                groupCard: action.payload,
            };
        default:
            return state;
    }
};

export default groupsReducer;

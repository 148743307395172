declare const ymaps: LocalAnyType;

import React, { useCallback, useEffect, useState } from 'react';
import { LocalAnyType } from '../../helpers/type.helper';
import { Root, MapWrap } from './map.styles';

type MapComponentProps = {
    coords: number[];
    updateCoordsCallback?: (coords: number[]) => void;
    disable?: boolean;
};

const MapComponent = ({ coords, updateCoordsCallback, disable }: MapComponentProps) => {
    const [myMap, setMyMap] = useState<LocalAnyType>(null);

    useEffect(() => {
        if (myMap) {
            return;
        }
        ymaps.ready(initMap);
    }, []);

    const initMap = useCallback(() => {
        const map = new ymaps.Map('map-container', {
            center: coords,
            zoom: 10,
        });
        const myPlacemark = new ymaps.Placemark(coords, null, {
            preset: 'islands#blueDotIcon',
            draggable: !disable,
        });
        myPlacemark.events.add('dragend', (e: LocalAnyType) => {
            const cord = e.get('target').geometry.getCoordinates();
            if (updateCoordsCallback) {
                updateCoordsCallback(cord);
            }
        });
        map.geoObjects.add(myPlacemark);
        setMyMap(map);
    }, [coords, setMyMap, disable, updateCoordsCallback]);
    return (
        <Root>
            <MapWrap id="map-container" />
        </Root>
    );
};

export default MapComponent;

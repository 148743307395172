import * as React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from '../global/redux/store';
import { MainWrap } from '../global/components/main-wrap/main-wrap';
import MembersList from '../modules/members/pages/list/members-list.component';
import MemberCard from '../modules/members/pages/card/member-card.component';
import MemberEdit from '../modules/members/pages/edit/member-edit.component';
import MemberCreate from '../modules/members/pages/create/member-create.component';
import { AuthWrap } from '../global/components/auth-wrap/auth-wrap';
import Login from '../auth/pages/login/login.component';
import Registration from '../auth/pages/registration/registration.component';
import MinistriesList from '../modules/ministries/pages/list/ministries-list.component';
import FamilyList from '../modules/family/pages/list/family-list.component';
import FamilyCreate from '../modules/family/pages/create/family-create.component';
import FamilyCard from '../modules/family/pages/card/family-card.component';
import FamilyEdit from '../modules/family/pages/edit/family-edit.component';
import MinistryCreate from '../modules/ministries/pages/create/ministries-create.component';
import MinistryCard from '../modules/ministries/pages/card/ministry-card.component';
import MinistryEdit from '../modules/ministries/pages/edit/ministry-edit.component';
import GroupCreate from '../modules/groups/pages/create/groups-create.component';
import GroupEdit from '../modules/groups/pages/edit/group-edit.component';
import GroupCard from '../modules/groups/pages/card/group-card.component';
import GroupsList from '../modules/groups/pages/list/groups-list.component';
import ResourcesCreate from '../modules/resources/pages/create/resources-create.component';
import ResourcesEdit from '../modules/resources/pages/edit/resources-edit.component';
import ResourcesCard from '../modules/resources/pages/card/resources-card.component';
import ResourcesList from '../modules/resources/pages/list/resources-list.component';
import ChatInfoList from '../modules/chat-info/pages/list/chat-info-list.component';
import ChatInfoCard from '../modules/chat-info/pages/card/chat-info-card.component';
// import MessagesList from '../modules/daily-messages/pages/list/messages-list.component';
// import MessageCreate from '../modules/daily-messages/pages/create/messages-create.component';
// import MessageCard from '../modules/daily-messages/pages/card/message-card.component';
// import MessageEdit from '../modules/daily-messages/pages/edit/message-edit.component';
import UsersList from '../modules/users/pages/list/users-list.component';
import UserCreate from '../modules/users/pages/create/users-create.component';
import UserCard from '../modules/users/pages/card/user-card.component';
import UserEdit from '../modules/users/pages/edit/user-edit.component';
import SettingsCard from '../modules/service/pages/card/settings-card.component';
import SettingsEdit from '../modules/service/pages/edit/settings-edit.component';
import OVCohortList from '../modules/ov/pages/cohort/list/ov-cohort-list.component';
import OVCohortCreate from '../modules/ov/pages/cohort/create/ov-cohort.component';
import OVCohortEdit from '../modules/ov/pages/cohort/edit/ov-cohort-edit.component';
import OVQuestionList from '../modules/ov/pages/questions/list/ov-question-list.component';
import OVCohortCard from '../modules/ov/pages/cohort/card/ov-cohort-card.component';

const MainRouter = () => {
    return (
        <Provider store={store}>
            <HashRouter>
                <MainWrap>
                    <Switch>
                        <Route path="/login" exact component={Login} />
                        <Route
                            path="/registration/:hash"
                            exact
                            component={Registration}
                        />
                        <AuthWrap>
                            <Switch>
                                <Route path="/" exact component={MembersList} />
                                <Route
                                    path="/members"
                                    exact
                                    component={MembersList}
                                />
                                <Route
                                    path="/member/create"
                                    exact
                                    component={MemberCreate}
                                />
                                <Route
                                    path="/member/:id"
                                    exact
                                    component={MemberCard}
                                />
                                <Route
                                    path="/member/:id/edit"
                                    exact
                                    component={MemberEdit}
                                />
                                <Route
                                    path="/ministries"
                                    exact
                                    component={MinistriesList}
                                />
                                <Route
                                    path="/ministry/create"
                                    exact
                                    component={MinistryCreate}
                                />
                                <Route
                                    path="/ministry/:id"
                                    exact
                                    component={MinistryCard}
                                />
                                <Route
                                    path="/ministry/:id/edit"
                                    exact
                                    component={MinistryEdit}
                                />
                                <Route
                                    path="/groups"
                                    exact
                                    component={GroupsList}
                                />
                                <Route
                                    path="/group/create"
                                    exact
                                    component={GroupCreate}
                                />
                                <Route
                                    path="/group/:id/edit"
                                    exact
                                    component={GroupEdit}
                                />
                                <Route
                                    path="/group/:id"
                                    exact
                                    component={GroupCard}
                                />
                                <Route
                                    path="/family"
                                    exact
                                    component={FamilyList}
                                />
                                <Route
                                    path="/family/create"
                                    exact
                                    component={FamilyCreate}
                                />
                                <Route
                                    path="/family/:id"
                                    exact
                                    component={FamilyCard}
                                />
                                <Route
                                    path="/family/:id/edit"
                                    exact
                                    component={FamilyEdit}
                                />
                                <Route
                                    path="/resources"
                                    exact
                                    component={ResourcesList}
                                />
                                <Route
                                    path="/resources/create"
                                    exact
                                    component={ResourcesCreate}
                                />
                                <Route
                                    path="/resources/:id"
                                    exact
                                    component={ResourcesCard}
                                />
                                <Route
                                    path="/resources/:id/edit"
                                    exact
                                    component={ResourcesEdit}
                                />
                                <Route
                                    path="/chat-info"
                                    exact
                                    component={ChatInfoList}
                                />
                                <Route
                                    path="/chat-info/:id"
                                    exact
                                    component={ChatInfoCard}
                                />
                                {/* <Route path="/daily-messages" exact component={MessagesList} />
                                <Route
                                    path="/daily-messages/create"
                                    exact
                                    component={MessageCreate}
                                />
                                <Route
                                    path="/daily-messages/:id/edit"
                                    exact
                                    component={MessageEdit}
                                />
                                <Route
                                    path="/daily-messages/:id"
                                    exact
                                    component={MessageCard}
                                /> */}
                                <Route
                                    path="/users"
                                    exact
                                    component={UsersList}
                                />
                                <Route
                                    path="/user/create"
                                    exact
                                    component={UserCreate}
                                />
                                <Route
                                    path="/user/:id/edit"
                                    exact
                                    component={UserEdit}
                                />
                                <Route
                                    path="/user/:id"
                                    exact
                                    component={UserCard}
                                />
                                <Route
                                    path="/ov/cohorts"
                                    exact
                                    component={OVCohortList}
                                />
                                <Route
                                    path="/ov/cohort/create"
                                    exact
                                    component={OVCohortCreate}
                                />
                                <Route
                                    path="/ov/cohort/:id"
                                    exact
                                    component={OVCohortCard}
                                />
                                <Route
                                    path="/ov/cohort/:id/edit"
                                    exact
                                    component={OVCohortEdit}
                                />
                                <Route
                                    path="/ov/questions"
                                    exact
                                    component={OVQuestionList}
                                />
                                <Route
                                    path="/service-settings"
                                    exact
                                    component={SettingsCard}
                                />
                                <Route
                                    path="/service-settings/edit"
                                    exact
                                    component={SettingsEdit}
                                />
                            </Switch>
                        </AuthWrap>
                    </Switch>
                </MainWrap>
            </HashRouter>
        </Provider>
    );
};

export default MainRouter;
